import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import api from "./api";

import {
  BootstrapVue,
  BIconBoundingBoxCircles,
  BIconFolderSymlink,
  BIconPencil,
  BIconTrashFill,
  BIconGearFill,
  BIconEyeSlashFill,
  BIconClockHistory,
  BIconCircleHalf,
  BIconBrightnessHigh,
  BIconQuestionCircle,
  BIconArrowCounterclockwise,
  BIconInfoCircleFill,
} from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import VuePluralize from "vue-pluralize";

Vue.use(BootstrapVue);
Vue.use(VuePluralize);
Vue.component("BIconBoundingBoxCircles", BIconBoundingBoxCircles);
Vue.component("BIconFolderSymlink", BIconFolderSymlink);
Vue.component("BIconPencil", BIconPencil);
Vue.component("BIconTrashFill", BIconTrashFill);
Vue.component("BIconGearFill", BIconGearFill);
Vue.component("BIconEyeSlashFill", BIconEyeSlashFill);
Vue.component("BIconClockHistory", BIconClockHistory);
Vue.component("BIconCircleHalf", BIconCircleHalf);
Vue.component("BIconBrightnessHigh", BIconBrightnessHigh);
Vue.component("BIconQuestionCircle", BIconQuestionCircle);
Vue.component("BIconArrowCounterclockwise", BIconArrowCounterclockwise);
Vue.component("BIconInfoCircleFill", BIconInfoCircleFill);

Vue.config.productionTip = false;

api.init();

router.beforeEach((to, _from, next) => {
  document.title = to.meta.title;
  next();
});

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
